<template>
  <div class="artist-detail-container">
    <!-- 轮播图 -->
    <van-swipe
      class="my-swipe"
      :loop="true"
      indicator-color="white"
      @change="onChange"
    >
      <van-swipe-item
        v-for="(item, index) in goodList"
        :key="index"
      >
        <goods-item
          :videoIsPlay="videoIsPlay"
          :goodsType="item.type"
          :goodsSrc="item.img"
          :goodsPoster="item.coverImg"
          :goodsSource="item.detailImg"
        ></goods-item>
      </van-swipe-item>
    </van-swipe>
    <!-- 馆藏简介 -->
    <div class="management-info">
      <img
        class="management-info-avatar"
        :src="managementInfo.avatar"
      />
      <p class="management-info-title">{{ managementInfo.title }}</p>
      <p class="management-info-content">
        {{ managementInfoContent }}
        <a
          @click="showMore"
          v-if="managementInfo.content && managementInfo.content.length > 80 && managementInfoContent.length <= 83"
        >
          展开
          <img />
          <i
            style="font-weight: bold;"
            class="el-icon-arrow-down"
          ></i>
        </a>
        <a
          @click="hideMore"
          v-if="managementInfoContent && managementInfoContent.length > 83"
        >
          收起
          <img />
          <i
            style="font-weight: bold;"
            class="el-icon-arrow-up"
          ></i>
        </a>
      </p>
    </div>
    <!-- 馆藏商品 -->
    <div class="order-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo row-start"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item
          index="1"
          class="el-menu-item1"
        >
          全部
          <img
            v-if="activeIndex == 1"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item
          index="2"
          class="el-menu-item1"
        >
          即将开售
          <img
            v-if="activeIndex == 2"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="3">
          已售罄
          <img
            v-if="activeIndex == 3"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
      </el-menu>
    </div>
    <van-list
      v-if="!noList"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
    >
      <div class="collection-list">
        <collection-item
          v-for="item in collectionList"
          :key="item.id"
          :collectionInfo="item"
        >
        </collection-item>
      </div>

    </van-list>
    <div
      v-if="noList"
      class="col-center"
      style="width:100%;margin-top:10vh;"
    >
      <img
        style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
        src="../assets/icon/no-list.png"
      />
      <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;margin-bottom: 5vh;">暂无数据</p>
    </div>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import GoodsItem from '../components/GoodsItem.vue'
import CollectionItem from '../components/CollectionItem.vue'

import { Swipe,SwipeItem,List,Popup } from 'vant';
import { Menu,MenuItem } from 'element-ui';
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(List);
Vue.use(Popup);

export default {
  components: {
    GoodsItem,CollectionItem
  },
  data () {
    return {
      videoIsPlay: true,
      goodsParam: {
        page: 0,
        limit: 12,
      },
      noList: false,
      goodList: [
      ],
      loading: false,
      finished: false,
      collectionList: [
      ],
      allProductList: [],
      openSoonProductList: [],
      soldProductList: [],
      managementInfoContent: '',
      managementInfo: {
        avatar: '',
        title: '',
        content: ''
      },
      activeIndex: '1',
    }
  },
  // computed: {
  //   showMore () {
  //     this.
  //   }
  // },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
  },
  methods: {
    // 图片类型
    imageType (type) {
      return type == 'bmp' ||
        type == 'jpg' ||
        type == 'jpeg' ||
        type == 'png' ||
        type == 'gif'
    },
    // 视频类型
    videoType (type) {
      return type == 'avi' ||
        type == 'wmv' ||
        type == 'mpeg' ||
        type == 'mp4' ||
        type == 'm4v' ||
        type == 'mov' ||
        type == 'flv' ||
        type == 'f4v' ||
        type == 'rmvb' ||
        type == '3gp'
    },
    // 下拉加载
    load () {
      this.goodsParam.page += 1
      if (this.allProductList.length == 0) {
        this.loading = true
        this.getArtistInfo()
      } else {
        this.selectManagementInfo()
      }
    },
    // 根据tab切换改变内容
    selectManagementInfo () {
      var productList = this.activeIndex == '1' ? this.allProductList : this.activeIndex == '2' ? this.openSoonProductList : this.soldProductList
      this.noList = productList.length == 0 ? true : false
      productList.map((i,j) => {
        this.collectionList.push({
          id: i.id,
          image: i.image,
          name: i.storeName,
          exclusiveBuyType: i.exclusiveBuyType,
          limitInfo: i.limitInfo,
          price: i.price,
          isOpen: i.isOpen,
          cateId: i.cateId,
          stock: i.stock,
          sellStatus: i.sellStatus,
          startTime: i.startTime,
          endTime: i.endTime
        })
      })
      this.finished = true
      this.loading = true
    },
    // 获取管藏详情
    getArtistInfo () {
      api
        .get('artist/' + this.$route.query.uid)
        .then(result => {
          if (result.data.success) {
            this.managementInfo.avatar = result.data.data.avatar
            this.managementInfo.title = result.data.data.realName
            this.managementInfo.content = result.data.data.brief
            this.managementInfoContent = this.managementInfo.content ? (this.managementInfo.content.length > 80 ? this.managementInfo.content.substring(0,80) + '...' : this.managementInfo.content) : ''


            result.data.data.materials.map(i => {
              if (this.imageType(i.name.split('.')[i.name.split('.').length - 1])) {
                this.goodList.push({
                  type: 'img',
                  img: i.url
                })
              } else if (this.videoType(i.name.split('.')[i.name.split('.').length - 1])) {
                this.goodList.push({
                  type: 'video',
                  detailImg: i.url
                })
              }
            })
            // 全部商品
            this.allProductList = result.data.data.products
            // 即将开售商品
            this.soldProductList = this.allProductList.filter(i => ((i.endTime && (((new Date()).getTime()) > (new Date(i.endTime.replace(/-/g,'/'))).getTime())) || i.stock == 0 || i.sellStatus == '已售罄' || i.sellStatus == '售完') && i.isOpen == 1)
            // 已售罄商品
            this.openSoonProductList = this.allProductList.filter(i => (i.sellStatus == '即将开放' || i.endTime && (new Date(i.endTime.replace(/-/g,"/"))).getTime() > (new Date()).getTime() && i.startTime && (new Date(i.startTime.replace(/-/g,"/"))).getTime() > (new Date()).getTime()) && i.isOpen == 1)

            this.selectManagementInfo()
          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    getManagementInfo () {
      // 下拉加载数据逻辑
    },
    showMore () {
      this.managementInfoContent = this.managementInfo.content
    },
    hideMore () {
      this.managementInfoContent = this.managementInfo.content.substring(0,80) + '...'
    },
    onChange () {
      this.videoIsPlay = this.videoIsPlay ? false : true
    },
    // tab切换
    handleSelect (key) {
      this.activeIndex = key
      this.goodsParam.page = 0
      this.collectionList = []
      this.load()
    },
  },
}
</script>
<style lang="less" scoped>
.artist-detail-container {
  width: 100vw;
  height: auto;
  background: #1c1c1c;
  /deep/.van-swipe__indicators {
    bottom: 10px;
    left: auto;
    right: -1%;
  }
  /deep/.van-swipe__indicator {
    width: 5px;
    height: 5px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 100%;
    opacity: 1;
    -webkit-transition: opacity 1s, background-color 0.2s;
    transition: opacity 1s, background-color 0.2s;
  }
  .management-info {
    width: 94vw;
    min-height: 36px;
    padding: 17px 0;
    height: auto;
    background: rgba(42, 42, 42, 0.89);
    backdrop-filter: blur(8px);
    margin-left: 3%;
    margin-top: 0px;
    position: relative;
    .management-info-avatar {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 50%;
      position: absolute;
      left: 1.5rem;
      top: -25px;
    }
    .management-info-title {
      width: 90%;
      margin-left: 5%;
      text-align: left;
      font-size: 22px;
      font-family: lantingheiweight;
      font-weight: bold;
      color: #ffffff;
      line-height: 31px;
      letter-spacing: 1px;
      margin-top: 26px;
    }
    .management-info-content {
      width: 90%;
      margin-left: 5%;
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: 700;
      color: #ffffff;
      line-height: 24px;
      margin-top: 10px;
      letter-spacing: 1px;
    }
  }
  .order-menu {
    width: 94vw;
    margin-left: 3vw;
    position: inherit;
    background: transparent !important;
    .el-menu {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu-item {
      font-size: 14px;
    }
    .el-menu-item1 {
      margin-right: 20px;
    }
  }
  .collection-list {
    width: 94vw;
    margin-left: 3vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  }
}
</style>